//
// Attr
//

@mixin attr($attr, $value, $important: '') {
    @if  $value != null  {
        #{$attr}: #{$value} #{$important};
    }
}


