//
// Text
//


.fs-20 {
    font-size: 18px;
}

.align-item-center{
    align-items: center !important;
}

.justify-content-space-between{
    justify-content: space-between;
}

.text-theme-blue {
   color: #053076 !important;

}

.text-theme-green
{
 color: #5AB231 !important;
 
}

.btn-theme-green{
    background-color:  #59B230 !important; 
    color: white !important;
    min-width: 108px;
    min-width: 140px;
}




.btn-theme {
   background-color: #003577 !important;
   min-width: 140px;
}
.mt-100{ 
    margin-top:100px
}
.mt-50{    
    margin-top: 50px
}


// Theme Colors
@each $name, $color in $theme-text-colors {
    // Base color
    .text-#{$name} {
        color: $color !important;
    }

    // Inverse color
    .text-inverse-#{$name} {
        color: theme-inverse-color($name) !important;
    }

    // Light colors
    .text-light-#{$name} {
        color: theme-light-color($name) !important;
    }

    // Text hover
    a.text-hover-#{$name},
    .text-hover-#{$name} {
        transition: $transition-link;

        &:hover {
            transition:$transition-link;
            color: $color !important;

            i {
				color: $color !important;
			}

			.svg-icon {
				@include svg-icon-color($color);
			}
        }
    }

    // Parent hover
    .hoverable {
        &:hover {
            a.text-hover-#{$name},
            .text-hover-#{$name} {
                transition:$transition-link;
                color: $color !important;

                i {
    				color: $color !important;
    			}

    			.svg-icon {
    				@include svg-icon-color($color, true);
    			}
            }
        }
    }
}

// Font sizes
.font-size-base {
    font-size:  $font-size-base;
}

.font-size-sm {
    font-size:  $font-size-sm;
}

.font-size-xs {
    font-size:  $font-size-xs;
}

.font-size-lg {
    font-size:  $font-size-lg;
}

// Responsive font sizes
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $name, $value in $font-sizes {
            .#{$name}#{$infix} {
                font-size: $value !important;
            }
        }
    }
}

// Boldest font
.font-weight-boldest {
    font-weight: $font-weight-boldest;
}

// Line heights
.line-height-0 {
    line-height: 0;
}

.line-height-xs {
    line-height: 0.75;
}

.line-height-sm {
    line-height: 1;
}

.line-height-md {
    line-height: 1.25;
}

.line-height-lg {
    line-height: 1.5;
}

.line-height-xl {
    line-height: 1.75;
}

// Text transparent
.text-transparent{
    color: transparent;
}

// Text stroke
.text-stroke{
    -webkit-text-stroke-width: 0.35rem;
    -moz-text-stroke-width: 0.35rem;
    text-stroke-width: 0.35rem;
    -webkit-text-stroke-color: $white;
    -moz-text-stroke-color: $white;
    text-stroke-color: $white;
}

// Cursor pointer
.cursor-pointer {
    cursor: pointer;
}

// Cursor move
.cursor-move {
    cursor: move;
}

// Link transition
a {
    transition:$transition-link;

    &:hover {
        transition:$transition-link;
    }
}

a:hover {
    /* color: #0073e9; */
    text-decoration: underline;
}


.center-flex {
    margin-top:50px;
    display: flex !important;
    align-items: center;
}

.c-p {
    cursor: pointer !important;
}

.inline-table {
    display: inline-table !important;
}


.react-bootstrap-table  {
    background: white !important;
}

.react-bootstrap-table-pagination {
     margin-right: 0px; 
     margin-left: 0px;
     background: white !important;

}


.react-bootstrap-table-pagination-list{ display: flex;justify-content: flex-end }

.nav.nav-pills .show > .nav-link, .nav.nav-pills .nav-link.active{
    background-color: #003577 !important;
}


.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 300px;
    outline: none;
    background-color: #f1f3f9 !important; 
    border-color: #EBEDF3 !important;
    color: #3F4254 !important;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease !important;
    width: 100% !important;
    min-height: 38px !important;
}

.nav.nav-pills .nav-link {
 background-color: whitesmoke !important;
}

.justify-content-center{
    justify-content: center !important;
}
.btn-con{
    padding-left: 75px !important;
    padding-right: 75px !important;
}



.MuiInputLabel-filled {
    z-index: 1;
    transform: translate(12px, 20px) scale(1);
    pointer-events: none;
    display: none !important;
}

.MuiFilledInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms transparent 0ms !important;
    border-bottom: 0px solid transparent !important;
    pointer-events: none !important;
}


.MuiFilledInput-root{
    background-color: #EBEDF3 !important;
}

.MuiFilledInput-root:hover{
    background-color: #EBEDF3 !important;
}


@media screen and (min-width: 991px){
    #kt_login_signin_form
    {
      width: 60%;
    }
  }

  @media screen and (max-width: 991px){
    .sm-center
    {
      justify-content: center !important;
    }
  }


  

  .text-grey {
    color: grey ;
  }

  .text-dark {
      color:#000
  }

  .auth-btns {
    min-height: 50px;
  }

  .auth-btns-text {
      font-size: 18.5px;
  }

.no-drop 
{
    cursor: not-allowed !important;
}

.fa-plus-circle{
    font-size: 22px;
}

.d-c  {
    display: contents !important;
}
.font-weight-bold {
    font-weight: 600 !important;
}

.css-2b097c-container {
    background-color: #F3F6F9 !important;
    border-color: #F3F6F9 !important;
    color: #3F4254 !important;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease !important;
}

.text-muted {
    color: #6c757d!important;
}


.text-success {
    color: #59B230 !important;
    font-weight: 800 !important;
}

@media (max-width: 768px){
   
    .text-center-sm {
        text-align: center !important;
    }

}
